<template>
  <div>
    <ValidationObserver
      ref="observer"
      tag="form"
      @submit.prevent="submitForm"
      novalidate
    >
      <div class="form-group row">
        <label class="col-2" for="name">#ID:</label>
        <div class="col">
          {{ form.id }}
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2" for="name">ชื่อหวย:</label>
        <div class="col">
          {{ form.name }}
        </div>
      </div>
      <div class="form-group row">
        <label class="col-2" for="closeTime">เวลาออกผล:</label>
        <div class="col">
          {{ $date(form.closeDate).format("DD/MM/YYYY HH:mm:ss") }}
        </div>
      </div>
      <div class="form-group row" v-if="form.lottoCategoryId == 1">
        <label class="col-2" for="result">ผลรางวัล:</label>
        <div class="col">
          <ValidationProvider rules="required|min:5|max:5" v-slot="v">
            <input
              type="text"
              class="form-control"
              id="result"
              @keypress="$root.onlyNumber"
              v-model="form.result"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div v-if="form.lottoCategoryId == 2 || form.lottoCategoryId == 5">
        <div class="form-group row">
          <label class="col-2" for="result">รางวัลที่1:</label>
          <div class="col">
            <ValidationProvider rules="required|min:5|max:5" v-slot="v">
              <input
                type="text"
                class="form-control"
                id="result"
                @keypress="$root.onlyNumber"
                v-model="form.result"
              />
              <span class="text-danger">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-2" for="name">รางวัลที่2:</label>
          <div class="col">
            <ValidationProvider rules="required|min:5|max:5" v-slot="v">
              <input
                type="text"
                class="form-control"
                id="name"
                @keypress="$root.onlyNumber"
                v-model="form.result2nd"
              />
              <span class="text-danger">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-2"></div>
        <div class="col">
          <button
            class="btn btn-danger mr-1"
            type="button"
            @click="$router.go(-1)"
          >
            ยกเลิก
          </button>
          <button class="btn btn-primary" type="submit">บันทึก</button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      id: this.$route.params.id,
    }
  },
  methods: {
    async submitForm() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()
      try {
        await this.axios({
          method: "put",
          url: "lotto/round",
          data: this.form,
        })

        await this.swal.swalSuccess()
        this.$store.dispatch("forceReload")
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  async mounted() {
    if (!this.id) {
      return
    }

    this.swal.processing()
    try {
      let res = await this.axios({
        method: "get",
        url: `lotto/${this.id}/round`,
      })

      this.form = res.data

      this.swal.close()
    } catch (e) {
      console.log(e)
      this.swal.swalError()
    }
  },
}
</script>